<template>
    <!-- 大赛报告 -- 参赛人员分布玫瑰图 -->
    <div class="member-chart" id="member-chart"></div>
</template>

<script>
    export default {
        props: {
            reportType: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                memberData: [], //参赛人员分布饼图数据
            }
        },
        methods: {
            // 获取参赛人员分布情况
            async getAreaMemberInfo() {
                let params = {
                    stageId: this.$route.params.id,
                    type: this.reportType,
                }
                let resData = await this.$Api.Activity.getAreaMemberInfo(params);
                // console.log(resData);
                this.memberData = resData.data
                this.memberData.map(item => {
                    item.value = item.count + 1
                })
                this.initMemberChart();
            },
            // 参赛人员分布 
            initMemberChart() {
                var chart = document.getElementById('member-chart');
                var memberChart = this.$echarts.init(chart);
                window.addEventListener('resize', () => {
                    memberChart.resize()
                })
                let option = {
                    color: ['#FF655D', '#6ED2D4', '#50ACED', '#FDB94E', '#44E188'],
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            // console.log(params);
                            let str =
                                `参赛人员分布  </br>${params.marker} ${params.data.name}: ${params.value - 1 }`;
                            return str;
                        },
                    },
                    series: [{
                        name: '参赛人员分布',
                        type: 'pie',
                        radius: ["20%", "80%"],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: this.memberData,
                    }]
                }
                memberChart.setOption(option)
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getAreaMemberInfo();
            })

        }
    }
</script>

<style lang="less" scoped>
    .member-chart {
        width: 455px;
        height: 220px;

        @media screen and (min-width:1250px) {
            width: 550px;
            height: 260px;
        }
    }
</style>