<template>
    <!-- 大赛报告 组别比例 -->
    <div class="gender-prop">
        <div class="gender flex-between">
            <div class="tc">
                <img src="@/assets/images/course/boy.png" alt="" class="boy">
                <p v-if="JSON.stringify(genderData) !== '[]'">平均正确率：{{genderData[0].avg.toFixed(2) || 0}}%
                </p>
            </div>
            <span class="vs-tips">VS</span>
            <div class="tc">
                <img src="@/assets/images/course/girl.png" alt="" class="girl">
                <p v-if="JSON.stringify(genderData) !== '[]'">平均正确率：{{genderData[1].avg.toFixed(2) || 0}}%
                </p>
            </div>
        </div>
        <div class="progress">
            <el-tooltip effect="dark" :content="`男生占：${genderData[0].countRate}%`" placement="top"
                v-if="JSON.stringify(genderData) !== '[]' && genderData[0].countRate != 0">
                <div class="left-progress progress-item csp" :style="{'width': `${genderData[0].countRate}%` || 0}">
                    {{genderData[0].countRate}}%
                </div>
            </el-tooltip>
            <el-tooltip effect="dark" :content="`女生占：${genderData[1].countRate}%`" placement="top"
                v-if="JSON.stringify(genderData) !== '[]' && genderData[1].countRate != 0">
                <div class="right-progress progress-item csp"
                    :style="{'width': `${Number(genderData[1].countRate) + 1}%` || 0}">
                    {{genderData[1].countRate}}%
                </div>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            reportType: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                genderData: [{
                        countRate: 0,
                        avg: 0
                    },
                    {
                        countRate: 0,
                        avg: 0
                    }
                ], //男女生比例数据
            }
        },
        methods: {
            /// 获取性别信息
            async getGenderInfo() {
                let params = {
                    stageId: this.$route.params.id,
                    type: this.reportType,
                }
                let resData = await this.$Api.Activity.getGenderInfo(params);
                // console.log(resData.data);
                this.genderData = resData.data;
                let countSum = 0;
                this.genderData.map(item => {
                    countSum += item.count
                })
                // console.log(countSum);
                this.genderData.map(item => {
                    item.countRate = ((item.count / countSum) * 100).toFixed(2);
                })

                // console.log(this.genderData);
            },

        },
        mounted() {
            this.getGenderInfo();
        }
    }
</script>

<style lang="less" scoped>
    .gender-prop {
        padding: 0 20px;
        box-sizing: border-box;

        .gender {
            padding: 0 30px;
            margin-top: 5px;

            &>img {
                width: 81px;
                height: 106px;
            }

            .boy+p {
                color: #00ADF3;
                font-size: 12px;
            }

            .girl+p {
                color: #FC7C4C;
                font-size: 12px;
            }

            .vs-tips {
                color: #FC7C4C;
                font-size: 50px;
                font-weight: 600;
                line-height: 140px;
            }
        }

        .progress {
            margin-top: 10px;
            height: 22px;
            border-radius: 25px;
            background-color: #00ADF3;
            background-origin: content-box;
            position: relative;

            .progress-item {
                display: inline-block;
                height: 22px;
                background-color: #00ADF3;
                vertical-align: middle;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
                color: #fff;
                text-align: center;
            }


            .left-progress {
                position: absolute;
                left: -1px;
                top: 0;
            }

            .right-progress {
                background-color: #FB7B4A;
                border-radius: 25px;
                position: absolute;
                right: -1px;
                top: 0;
            }
        }

        @media screen and (min-width:1250px) {
            padding: 0 25px;

            .gender {
                margin-top: 19px;

                &>img {
                    width: 98px;
                    height: 129px;
                }

                .boy+p {
                    font-size: 14px;
                }

                .girl+p {
                    font-size: 14px;
                }

                .vs-tips {
                    font-size: 55px;
                    line-height: 170px;
                }
            }

            .progress {
                height: 30px;

                .progress-item {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
</style>