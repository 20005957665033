<template>
    <!-- 大赛报告 -- 成绩分布玫瑰图 -->
    <div class="score-chart" id="score-chart"></div>
</template>

<script>
    export default {
        props: {
            reportType: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                scoreData: [], //参赛人员分布饼图数据
            }
        },
        methods: {
            // 获取成绩分布情况
            async getScoreInfo() {
                let params = {
                    stageId: this.$route.params.id,
                    type: this.reportType,
                }
                let resData = await this.$Api.Activity.getScoreInfo(params);
                // console.log(resData.data)
                this.scoreData = resData.data;
                this.scoreData.map(item => {
                    item.value = item.count + 1
                })
                this.initScoreCharts();
            },
            // 成绩分布
            initScoreCharts() {
                var chart = document.getElementById('score-chart');
                var scoreChart = this.$echarts.init(chart);
                window.addEventListener('resize', () => {
                    scoreChart.resize()
                })
                let option = {
                    color: ['#FF655D', '#6ED2D4', '#50ACED', '#FDB94E', '#44E188'],
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            // console.log(params);
                            let str = `成绩分布  </br>${params.marker} ${params.data.name}: ${params.value - 1 }`;
                            return str;
                        },
                    },
                    series: [{
                        name: '成绩分布',
                        type: 'pie',
                        radius: ["20%", "80%"],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: this.scoreData,
                    }]
                }
                scoreChart.setOption(option)
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getScoreInfo();
            })

        }
    }
</script>

<style lang="less" scoped>
    .score-chart {
        width: 455px;
        height: 220px;

        @media screen and (min-width:1250px) {
            width: 550px;
            height: 260px;
        }
    }
</style>