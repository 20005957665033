<template>
    <div class="leader-report-simulation" bgcolor="yellow">
        <!-- 参赛人数展示 -->
        <peopleCounter :number="peopleNumber"></peopleCounter>
        <!-- 参赛人员分析 -->
        <div class="report-join-data flex-between">
            <div class="join-discrib join-data-item" v-for="(item,index) in joinDataCharts" :key="index">
                <h5 class="join-data-title">{{item.title}}</h5>
                <component v-bind:is="item.name"></component>
            </div>
        </div>
        <!-- 班级筛选 -->
        <div class="report-detail-sort-wrapper flex-align">
            <div class="report-detail-sort">
                区域：
                <el-select v-model="areaValue" placeholder="请选择" size="mini" @change="areaChange">
                    <el-option v-for="item in areaFilterOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="report-detail-sort">
                学校：
                <el-select v-model="schoolValue" placeholder="请选择" size="mini" @change="filterChange"
                    :disabled="!Boolean(areaValue)">
                    <el-option v-for="item in schoolFilterOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="report-detail-sort">
                年级：
                <el-select v-model="gradeValue" placeholder="请选择" size="mini" @change="filterChange">
                    <el-option v-for="item in gradeFilterOption" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <!-- <div class="report-detail-sort">
                角色：
                <el-select v-model="roleValue" placeholder="请选择" size="mini">
                    <el-option v-for="item in roleFilterOption" :key="item.dictCode" :label="item.dictLabel" :value="item.dictCode">
                    </el-option>
                </el-select>
            </div> -->
            <button class="download-data csp">
                <i class="el-icon-upload2"></i>导出数据</button>
        </div>
        <!-- 模拟学情分析 -->
        <div class="simulation-analysis">
            <div class="simulation-title common-title flex-between">
                <h3>模拟学情分析</h3>
            </div>
            <ul class="simulation-data-count flex-between">
                <li v-for="item in simulationDataList" :key="item.id" class="simulation-data-item">
                    <h6 class="count-item-title">{{item.title}}</h6>
                    <p class="count-item-value wes">{{item.value == undefined ? '--':item.value}}<span
                            class="count-item-unit">{{item.value == undefined ? '':item.unit }}</span>
                        <button class="result-stu-btn high-btn csp" v-if="item.id > 4 && item.value"
                            @click="showRank(item)">
                            <i class="el-icon-user"></i>
                            {{item.childList.length}} 个{{item.title.split('的')[1]}}{{item.title.split('的')[0]}}
                        </button>
                    </p>
                    <ul id="stuList" class="stu-list" v-if="currentName == item.title">
                        <li v-for="each in item.childList" :key="each.userId" class="stu-list-li flex-align-between">
                            <div class="list-avatar-wrapper">
                                <img class="stu-list-avatar csp" src="@/assets/images/course/good.png"
                                    :onerror="$store.state.user.defaultAvatar" alt />
                            </div>
                            <span class="name">{{each.userName || '--'}}</span>
                            <span>{{each.showClass ? each.className:' '}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="simulation-charts flex-between">
                <div class="simulation-charts-item">
                    <h4 class="charts-item-title">每日成绩对比</h4>
                    <div class="lineCharts" id="lineCharts"></div>
                </div>
                <div class="simulation-charts-item">
                    <h4 class="charts-item-title">各维度具体参与情况</h4>
                    <div class="barCharts" id="barCharts"></div>
                </div>
            </div>
        </div>
        <!-- 阅读能力分析 -->
        <div class="read-analysis">
            <div class="common-title reading-title  flex-between">
                <h3>阅读能力分析</h3>
            </div>
            <div class="read-data flex-between">
                <readingRadar :radarData="radarData" :radarFilterData="radarFilterData" :requestType="requestType">
                </readingRadar>
                <div class="read-data-split"></div>
                <readingDesc :readingDescData="readingDescData"></readingDesc>
            </div>
        </div>
    </div>
</template>

<script>
    import peopleCounter from './report-charts/people-counter.vue'; //参赛人数
    import joinMember from './report-charts/member-pie.vue'; //参赛人员分布组件
    import score from './report-charts/score-pie.vue'; //成绩分布
    import group from './report-charts/group-bar.vue'; //组别比例
    import gender from './report-charts/gender-prop.vue'; //男女比例
    import readingDesc from './report-charts/reading-description.vue'; //阅读能力描述
    import readingRadar from './report-charts/reading-radar.vue'; //阅读能力雷达图
    export default {
        components: {
            peopleCounter,
            joinMember,
            score,
            group,
            gender,
            readingRadar,
            readingDesc,
        },
        data() {
            return {
                peopleNumber: 0, //参赛人数
                joinDataCharts: [{
                        title: "参赛人员分布",
                        name: 'joinMember'
                    },
                    {
                        title: "成绩分布",
                        name: 'score'
                    },
                    {
                        title: "男女比例",
                        name: 'gender'
                    },
                    {
                        title: "组别比例",
                        name: 'group'
                    },
                ],
                areaValue: null, // 区域筛选值
                areaFilterOptions: [{ // 区域筛选列表
                    id: null,
                    name: '全部'
                }],
                schoolFilter: true, // 学校可选状态
                schoolValue: null, // 学校筛选值
                schoolFilterOptions: [{ // 学校筛选列表
                    id: null,
                    name: '全部'
                }],
                gradeValue: null, // 年级筛选值
                gradeFilterOption: [{ // 年级筛选值
                    id: null,
                    name: '全部'
                }],
                // roleValue:null,
                // roleFilterOption:[{
                //     dictCode: null,
                //     dictLabel: '全部'
                // }],
                requestType: 6, // 接口请求类型
                simulationDataList: [{ // 模拟学情分析数据 
                    id: 1,
                    name: 'practiceTime',
                    title: '练习总时长',
                    value: undefined,
                    unit: '分钟',
                }, {
                    id: 2,
                    name: 'practiceCount',
                    title: '练习总次数',
                    value: undefined,
                    unit: '次',
                }, {
                    id: 3,
                    title: '平均每天练习次',
                    value: undefined,
                    unit: '次',
                }, {
                    id: 4,
                    title: '练习平均成绩', // practiceRate ÷ practiceStudentNum
                    value: undefined,
                    unit: '%',
                }],
                currentName: null,
                radarData: [], // 阅读能力雷达图数据 平均
                radarFilterData: [], // 阅读能力雷达图数据 筛选
                readingDescData: [], // 雷达图右侧的各维度最突出数据
                // 
                dayXAxisList: [], // 每日成绩对比、各维度具体参与情况 两个折线图的横轴列表
                dailyScoreData: [], // 平均成绩、平均练习次数 数据  从queryInfo接口取出
                dailyScoreFilterData: [], // 筛选
                lineDataListArray: [], // 每日成绩对比series数据列表
                otherDataListArray: [], // 各维度具体参与情况series数据列表
            }
        },
        mounted() {
            this.getStageTime();
            this.getFieldAreaAndSchool();
            this.getFieldGradeAndRole();
            this.waitReturnValue(3)
        },
        methods: {
            // 点击显示隐藏childList成绩排行列表
            showRank(item) {
                if (this.currentName == item.title) {
                    this.currentName = null;
                    return;
                }
                this.currentName = item.title;
            },
            // 模拟学情分析数据(筛选) [根据筛选取的数据]
            async getLeaderActiveReportInfo() {
                this.dailyScoreFilterData = [];
                this.radarFilterData = [];
                this.readingDescData = [];
                let params = {
                    stageId: this.$route.params.id,
                    type: this.requestType,
                    areaId: this.areaValue,
                    schoolId: this.schoolValue,
                    gradeId: this.gradeValue,
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params)
                // 请求数据为空处理
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;

                let dataInfo = resData.data[0];
                // 处理模拟学情分析4个可变数据
                this.handleSimulationData();
                let handleMostAndLessData = function (item, name) {
                    if (item.name == name && dataInfo[name] != '[]' && dataInfo[name]) {
                        Vue.set(item, 'value', JSON.parse(dataInfo[name])[0].userName)
                        Vue.set(item, 'childList', JSON.parse(dataInfo[name]))
                    }
                }
                this.simulationDataList.map((item) => {
                    // 相同name的进行赋值
                    if (dataInfo.hasOwnProperty(item.name)) {
                        item.value = dataInfo[item.name]
                    }
                    // 特殊数据处理 
                    // 1.练习总时长 秒转分
                    if (item.name == 'practiceTime') {
                        item.value = parseInt(item.value / 60)
                    }
                    // 2.练习平均成绩  练习总成绩 ÷ 练习总人数
                    if (item.title == '平均每天练习次') {
                        item.value = parseInt(dataInfo.practiceCount / dataInfo.practiceDay)
                        if (!item.value) item.value = 0;
                    }
                    // 2.平均每天练习次数  练习总成绩 ÷ 练习总人数
                    if (item.title == '练习平均成绩') {
                        item.value = parseInt(dataInfo.practiceRate / dataInfo.practiceStudentNum)
                    }
                    // 3.练习积极的
                    handleMostAndLessData(item, 'mostPracticeUser')
                    // 4.平均成绩最佳的区域
                    handleMostAndLessData(item, 'bestPracticeUser')
                    // 5.练习积极的学校
                    handleMostAndLessData(item, 'mostUserPri2')
                    // 6.平均成绩最佳的学校
                    handleMostAndLessData(item, 'bestUserPri2')
                })
                // 阅读能力分析右侧数据
                for (let i = 1; i <= 5; i++) {
                    if (dataInfo[`practiceAbilityInfo${i}`] == '[]') return;
                    this.readingDescData.push(JSON.parse(dataInfo[`practiceAbilityInfo${i}`])[0])
                }

                if (this.requestType != 6) {
                    // 折线图筛选后的数据
                    this.dailyScoreFilterData = dataInfo.dayInfo;
                    // 雷达图筛选后数据
                    this.radarFilterData = JSON.parse(dataInfo.practiceAbility);
                }
                return new Promise((resolve, reject) => {
                    resolve(this.dailyScoreFilterData)
                })
            },
            // 模拟学情分析数据全部) [取平均值和一些不变数据]
            async getLeaderActiveReportInfoAll() {
                let params = {
                    stageId: this.$route.params.id,
                    type: 6
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params)
                if (!resData.data || JSON.stringify(resData.data) == '[]') {
                    this.dailyScoreData = [];
                    this.radarData = [];
                    return;
                }
                let dataInfo = resData.data[0];
                // 参与人数
                this.peopleNumber = dataInfo.practiceStudentNum
                // 折线图线数据 平均值
                this.dailyScoreData = dataInfo.dayInfo;
                // 雷达图数据 平均值
                this.radarData = JSON.parse(dataInfo.practiceAbility);

                return new Promise((resolve, reject) => {
                    resolve(this.dailyScoreData)
                })
            },
            // 获取活动环节的时间 和 时间差值
            async getStageTime() {
                let params = {
                    stageId: this.$route.params.id
                }
                let resData = await this.$Api.Activity.getStageTime(params);
                // console.log(resData.data);
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;
                // 格式化开始时间和结束时间 
                // 1.不格式可能由于开始时间到结束时间不到24小时而减少一天 所以必须格式化
                let startTime = this.dayjs(resData.data[0]).format('YYYY-MM-DD');
                let endTime = this.dayjs(resData.data[1]).format('YYYY-MM-DD');
                // 2.计算开始时间到结束时间的差值
                let dayDiff = this.dayjs(endTime).diff(this.dayjs(startTime), 'day')
                // 3.循环并得出折线图x轴的离散数组  
                // 4.diff必须+1 因为要取最后一天的数据
                for (let i = 0; i < dayDiff + 1; i++) {
                    this.dayXAxisList.push(this.dayjs(startTime).add(i, 'day').format('MM月DD日'));
                }
                // console.log(this.dayXAxisList);
            },
            // 获取每日成绩对比、各维度具体参与情况 筛选后的各条线数据
            async getOtherLineInfo() {
                this.lineDataListArray = [];
                this.otherDataListArray = [];
                let params = {
                    stageId: this.$route.params.id,
                    type: this.requestType,
                    areaId: this.areaValue,
                    schoolId: this.schoolValue,
                    gradeId: this.gradeValue,
                }
                let resData = await this.$Api.Activity.getDaysInfo(params);
                // console.log(resData.data);
                if (JSON.stringify(resData.data) == '{}' || !resData.data) return;
                let keyList = Object.keys(resData.data);
                // console.log(keyList);
                for (var i = 0; i < keyList.length; i++) {
                    this.lineDataListArray.push(
                        this.lineDataHandle(resData.data[keyList[i]], keyList[i], 0)
                    )
                    this.otherDataListArray.push(
                        this.lineDataHandle(resData.data[keyList[i]], keyList[i], 1)
                    )
                }
                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 每日成绩对比数据处理,各维度具体情况数据处理
            lineDataHandle(valueList, key, type) {
                let valueMap = {}
                valueList.map(item => {
                    // 如果月份里不带0要补0
                    if (item.month.indexOf('0') == -1 && Number(item.month) < 10) {
                        item.month = `0${item.month}`
                    }
                    item.timeKey = `${item.month}月${item.day}日`
                    item.value = Number((item.rateAvg / item.participateCount).toFixed(2))
                    valueMap[item.timeKey] = type ? item.participateCount : item.value;
                })
                let lineData = []
                for (let i = 0; i < this.dayXAxisList.length; i++) {
                    if (valueMap[this.dayXAxisList[i]]) {
                        lineData.push(valueMap[this.dayXAxisList[i]])
                    } else {
                        lineData.push(0);
                    }
                }
                let obj = {}
                obj[key] = lineData;
                return obj
            },

            // 获取区域和学校的筛选项
            async getFieldAreaAndSchool() {
                let resData = await this.$Api.Activity.getFieldAreaAndSchool()
                this.areaFilterOptions = this.areaFilterOptions.concat(resData.data);
                // console.log('filter', resData);
                // console.log(this.areaFilterOptions);
            },
            // 区域变动学校筛选条件赋值
            areaChange(val) {
                this.schoolFilterOptions = [{
                    id: null,
                    name: '全部'
                }]
                this.schoolValue = null;
                this.checkoutFilter();
                if (!val) {
                    this.waitReturnValue(2)
                    return;
                }
                for (let i = 0; i < this.areaFilterOptions.length; i++) {
                    if (val == this.areaFilterOptions[i].id) {
                        this.schoolFilterOptions = this.schoolFilterOptions.concat(this.areaFilterOptions[i].children);
                        break;
                    }
                }
                this.waitReturnValue(2)
            },
            async getFieldGradeAndRole() {
                let resData = await this.$Api.Activity.getFieldGradeAndRole()
                // console.log('GradeAndRole', resData);
                this.gradeFilterOption = this.gradeFilterOption.concat(resData.data.grade);
                // this.roleFilterOption = this.roleFilterOption.concat(resData.data.role);
            },
            // 学校、年级筛选改变
            filterChange(val) {
                this.checkoutFilter();
                this.waitReturnValue(2)
            },
            // 等待接口完成
            waitReturnValue(type) {
                let avgData = this.getLeaderActiveReportInfoAll();
                let filterAvgData = this.getLeaderActiveReportInfo();
                let otherData = this.getOtherLineInfo();
                let arr = type === 2 ? [filterAvgData, otherData] : [avgData, filterAvgData, otherData]
                Promise.all(arr).then((values) => {
                    // console.log(values);
                    this.initSimulationCharts('lineCharts');
                    this.initSimulationCharts('barCharts');
                });
            },
            // 筛选情况检查
            checkoutFilter() {
                let area = this.areaValue ? '1' : '0'
                let school = this.schoolValue ? '1' : '0'
                let grade = this.gradeValue ? '1' : '0'
                // console.log(area, school, grade)
                let truthMap = {
                    '000': 6,
                    '001': 9,
                    '010': 4,
                    '011': 3,
                    '100': 5,
                    '101': 8,
                    '110': 4,
                    '111': 3,
                }
                this.requestType = truthMap[`${area}${school}${grade}`];
            },
            // 处理学情分析4个可变数据
            handleSimulationData() {
                let nameMap = {
                    3: ['班级', '学生'],
                    8: ['班级', '学生'],
                    9: ['班级', '学生'],
                    4: ['年级', '班级'],
                    5: ['学校', '年级'],
                    6: ['区域', '学校'],
                }
                let variableNameList = ['mostPracticeUser', 'bestPracticeUser', 'mostUserPri2', 'bestUserPri2']
                variableNameList.map((item, index) => {
                    let arrItem = {
                        id: `${this.requestType}${index}`,
                        name: item,
                    }
                    let splicText = index < 2 ? nameMap[this.requestType][0] : nameMap[this.requestType][1]
                    arrItem.title = item.indexOf('most') != -1 ? `练习积极的${splicText}` : `平均成绩最佳的${splicText}`
                    Vue.set(this.simulationDataList, index + 4, arrItem)
                })
            },
            // 每日成绩对比、维度具体参与情况折线图绘制
            initSimulationCharts(name) {
                var chartDom = document.getElementById(name);
                this.$echarts.init(chartDom).dispose();
                var chart = this.$echarts.init(chartDom);
                window.addEventListener('resize', () => {
                    chart.resize()
                })
                let nameMap = {
                    3: '年级平均',
                    8: '年级平均',
                    9: '年级平均',
                    4: '学校平均',
                    5: '区域平均',
                }
                let allDataArr = [];
                let chartType = name == 'lineCharts' ? 0 : 1
                // 平均值
                let avgName = chartType ? '活动平均练习次数' : '平均成绩'
                let avgLineData = this.lineDataHandle(this.dailyScoreData, avgName, chartType);
                allDataArr.push(avgLineData);
                // 筛选后的平均值
                if (this.requestType != 6) {
                    let filterAvgName = `${nameMap[this.requestType]}${chartType?'次数':'成绩'}`
                    let filterAvgLineData = this.lineDataHandle(this.dailyScoreData, filterAvgName, chartType);
                    allDataArr.push(filterAvgLineData);
                }
                // 其他数据
                let otherLineArr = chartType ? this.otherDataListArray : this.lineDataListArray;
                for (let i = 0; i < otherLineArr.length; i++) {
                    allDataArr.push(otherLineArr[i])
                }
                // 图表数据
                let series = [];
                for (let i = 0; i < allDataArr.length; i++) {
                    let key = Object.keys(allDataArr[i])[0]
                    series.push({
                        data: allDataArr[i][key],
                        type: 'line',
                        lineStyle: {
                            width: 1
                        },
                        name: key
                    })
                }
                // 为空状态
                if (JSON.stringify(this.dailyScoreData) == '[]' && JSON.stringify(otherLineArr) == '[]') {
                    series = [];
                }
                let lineOption = {
                    color: ['#EB4133', '#F8BD09', '#7832DA'],
                    tooltip: {
                        show: true
                    },
                    grid: {
                        top: "15%",
                    },
                    legend: {
                        show: true,
                        left: 'center',
                        bottom: 0,
                        itemWidth: 10,
                        itemHeight: 10,
                        icon: "rect",
                        textStyle: {
                            fontSize: 11,
                            lineHeigth: 14,
                            padding: [3, 0, 0, 0],
                            color: "#999"
                        },
                    },
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            // console.log(params);
                            return `${params.seriesName}</br>${params.marker}${params.name}：${params.value}${chartType?'次':'%'}`
                        }
                    },
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 80,
                        maxValueSpan: 6,
                        zoomOnMouseWheel: false,
                        zoomLock: true,
                        filterMode: 'none',
                    }],
                    xAxis: {
                        type: 'category',
                        data: this.dayXAxisList,
                        // max: xAxisMax,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(102, 102, 102, 0.08)"
                            },
                            interval: 0
                        },
                        axisLine: {
                            lineStyle: {
                                width: 2,
                                color: '#D3D3D3'
                            }
                        },
                        axisLabel: {
                            color: '#666666',
                            interval: 0,
                            fontSize: 10,
                            rotate: -20
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        splitNumber: 5,
                        axisLabel: {
                            formatter: '{value} %',
                            color: '#666666',
                        },
                        splitLine: {
                            show: true,
                            interval: 1,
                            lineStyle: {
                                color: "rgba(102, 102, 102, 0.08)"
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 2,
                                color: '#D3D3D3'
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: series
                }
                chart.setOption(lineOption)
            },

        },
    }
</script>

<style lang="less" scoped>
    @import "../less/leader_report.less";
</style>