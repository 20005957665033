<template>
    <!-- 领导者 -->
    <div class="active-report-teacher" v-wechat-title="`${$route.meta.title}_双优云桥-智慧研修平台`">
        <div class="active-report-wrapper manage-wrapper">
            <div class="active-report-container">
                <div class="switch-tabs">
                    <button v-for="(item,index) in ['区域','个人']" :key="index" class="switch-btn csp"
                        :class="{'switch-btn-active':activeTab == index}" @click="activeTab = index">{{item}}</button>
                </div>
                <!-- 区域 -->
                <div class="active-report-details" v-if="!activeTab">
                    <!-- 模拟、正式比赛切换按钮 -->
                    <!-- ['模拟练习情况','正式比赛情况'] -->
                    <div class="report-detail-switch">
                        <button v-for="item in ['模拟练习情况']" :key="item" class="detail-switch-btn csp"
                            :class="{'detail-switch-btn-active':detailActiveTab == item}"
                            @click="detailActiveTab = item">{{item}}</button>
                    </div>
                    <!-- 模拟练习情况 -->
                    <simulation v-if="detailActiveTab == '模拟练习情况'"></simulation>
                    <!-- 正式分析概览 -->
                    <real v-else></real>
                </div>
                <!-- 个人 -->
                <div class="active-report-details" v-if="activeTab">
                    <one></one>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import simulation from "./component/report_simulation.vue"
    import real from "./component/report_real.vue"
    import one from "../newactive/components/report_one.vue"
    export default {
        components: {
            simulation,
            real,
            one
        },
        data() {
            return {
                activeTab: 0,
                detailActiveTab: '模拟练习情况',
                sortValue: '选项1',
            }
        },
        methods: {},
        mounted() {
            this.$route.meta.title = this.$route.query.title;
        }
    }
</script>

<style lang="less" scoped>
    .active-report-teacher {
        .switch-tabs {
            text-align: center;
            margin-bottom: 34px;

            .switch-btn {
                border: 1px solid #508EF9;
                border-radius: 25px;
                line-height: 31px;

                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    padding: 0 33px 0 43px;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 0 43px 0 33px;
                }

                &.switch-btn-active {
                    background-color: #508EF9;
                    color: #fff;
                }
            }
        }

        .active-report-details {
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            background-color: #fff;
            // overflow: hidden;
        }

        .report-detail-switch {
            background-color: #F2F7FF;
            line-height: 57px;
            margin-bottom: 39px;
            overflow: hidden;
            border-top-left-radius: 7px;
            border-top-right-radius: 8px;

            .detail-switch-btn {
                font-size: 15px;
                padding: 0 28px;
                line-height: 58px;

                &.detail-switch-btn-active {
                    background-color: #fff;
                    color: #508EF9;
                    position: relative;

                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        height: 4px;
                        width: 100%;
                        background-color: #508EF9;
                    }
                }
            }

        }

        /deep/.report-content {
            margin-bottom: 0;
        }


        @media screen and (min-width:1250px) {
            .switch-tabs {
                margin-bottom: 41px;

                .switch-btn {
                    line-height: 38px;
                    font-size: 16px;

                    &:first-child {
                        padding: 0 44px 0 52px;
                    }

                    &:last-child {
                        padding: 0 52px 0 44px;
                    }
                }
            }

            .report-detail-switch {
                background-color: #F2F7FF;
                line-height: 70px;
                margin-bottom: 47px;

                .detail-switch-btn {
                    font-size: 15px;
                    padding: 0 35px;
                    line-height: 71px;
                }

            }
        }
    }
</style>