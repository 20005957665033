<template>
    <div class="report-detail-real">
        <!-- 参赛人数展示 -->
        <peopleCounter :number="peopleNumber"></peopleCounter>
        <!-- 参赛人员分析 -->
        <div class="report-join-data flex-between">
            <div class="join-discrib join-data-item" v-for="(item,index) in joinDataCharts" :key="index">
                <h5 class="join-data-title">{{item.title}}</h5>
                <component v-bind:is="item.name" :reportType="1"></component>
            </div>
        </div>
        <div class="report-detail-sort-wrapper flex-align">
            <div class="report-detail-sort">
                区域：
                <el-select v-model="areaValue" placeholder="请选择" size="mini" @change="areaChange">
                    <el-option v-for="item in areaFilterOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="report-detail-sort">
                学校：
                <el-select v-model="schoolValue" placeholder="请选择" size="mini" @change="filterChange"
                    :disabled="!Boolean(areaValue)">
                    <el-option v-for="item in schoolFilterOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="report-detail-sort">
                年级：
                <el-select v-model="gradeValue" placeholder="请选择" size="mini" @change="filterChange">
                    <el-option v-for="item in gradeFilterOption" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <!-- <div class="report-detail-sort">
                角色：
                <el-select v-model="sortValue" placeholder="请选择" size="mini">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
            <button class="download-data csp">
                <i class="el-icon-upload2"></i>导出数据</button>
        </div>
        <!-- 正式比赛概览 -->

        <div class="real-game-data">
            <h3 class="real-title">正式比赛概览</h3>
            <ul class="real-data-count flex-between">
                <li v-for="(item,index) in realDataList" :key="index" class="real-data-item">
                    <h6 class="count-item-title">{{item.title}}</h6>
                    <p class="count-item-value">{{item.value == undefined ? '--':item.value}}<span
                            class="count-item-unit">{{item.value == undefined ? '--':item.unit}}</span>
                        <button class="result-stu-btn high-btn csp" v-if="index > 1 && item.value"
                            @click="showRank(item)">
                            <i class="el-icon-user"></i>
                            {{item.childList.length}} 名学生取得最{{item.title == '最高成绩'?'高':'低'}}得分
                        </button>
                    </p>
                    <ul id="stuList" class="stu-list" v-if="currentName == item.title"
                        :class="{'down':item.title == '最低成绩'}">
                        <li v-for="each in item.childList" :key="each.userId" class="stu-list-li flex-align-between">
                            <div class="list-avatar-wrapper">
                                <img class="stu-list-avatar csp"
                                    :src="item.title == '最高成绩'? rankAvatar[0]:rankAvatar[1]"
                                    :onerror="$store.state.user.defaultAvatar" alt />
                            </div>
                            <span class="name">{{each.userName}}</span>
                            <span>{{each.className}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- 阅读能力分析 -->
        <div class="read-analysis">
            <div class="common-title reading-title flex-between ">
                <h3>阅读能力分析</h3>
            </div>
            <div class="read-data flex-between">
                <readingRadar :radarData="radarData" :radarFilterData="radarFilterData" :requestType="requestType">
                </readingRadar>
                <div class="read-data-split"></div>
                <readingDesc :readingDescData="readingDescData" :reportType="1"></readingDesc>
            </div>
        </div>
    </div>
</template>

<script>
    import peopleCounter from './report-charts/people-counter.vue'; //参赛人数
    import joinMember from './report-charts/member-pie.vue'; //参赛人员分布组件
    import score from './report-charts/score-pie.vue'; //参赛人员分布组件
    import group from './report-charts/group-bar.vue'; //组别比例
    import gender from './report-charts/gender-prop.vue'; //男女比例
    import readingDesc from './report-charts/reading-description.vue'; //阅读能力描述
    import readingRadar from './report-charts/reading-radar.vue'; //阅读能力雷达图
    export default {
        components: {
            peopleCounter,
            joinMember,
            score,
            group,
            gender,
            readingRadar,
            readingDesc
        },
        data() {
            return {
                peopleNumber: 0, //参与人数
                joinDataCharts: [{
                        title: "参赛人员分布",
                        name: 'joinMember'
                    },
                    {
                        title: "成绩分布",
                        name: 'score'
                    },
                    {
                        title: "男女比例",
                        name: 'gender'
                    },
                    {
                        title: "组别比例",
                        name: 'group'
                    },
                ],
                areaValue: null,
                areaFilterOptions: [{
                    id: null,
                    name: '全部'
                }],
                schoolFilter: true,
                schoolValue: null,
                schoolFilterOptions: [{
                    id: null,
                    name: '全部'
                }],
                gradeValue: null,
                gradeFilterOption: [{
                    id: null,
                    name: '全部'
                }],
                requestType: 6,
                realDataList: [{
                    name: 'duration',
                    title: '平均用时',
                    value: undefined,
                    unit: null,
                }, {
                    title: '平均成绩', //examRate ÷ examStudentNum
                    value: undefined,
                    unit: '%',
                }, {
                    name: 'mostRateUser',
                    title: '最高成绩',
                    value: undefined,
                    unit: '%',
                }, {
                    name: 'lessRateUser',
                    title: '最低成绩',
                    value: undefined,
                    unit: '%',
                }],
                currentName: null,
                rankAvatar: [
                    require('@/assets/images/course/good.png'),
                    require('@/assets/images/course/bad.png'),
                ],
                radarData: [], //阅读能力雷达图数据
                radarFilterData: [], //阅读能力雷达图数据 筛选
                readingDescData: [], //雷达图右侧的各维度最突出数据
            }
        },
        methods: {
            // 展示排行榜
            showRank(item) {
                if (this.currentName == item.title) {
                    this.currentName = null;
                    return;
                }
                this.currentName = item.title;
            },
            // 模拟学情分析数据
            async getLeaderActiveReportInfo() {
                this.radarFilterData = [];
                let params = {
                    stageId: this.$route.params.id,
                    type: this.requestType
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params)
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;

                let dataInfo = resData.data[0];
                let handleMostAndLessData = function (item, name) {
                    if (item.name == name && dataInfo[name] != '[]' && dataInfo[name]) {
                        Vue.set(item, 'value', JSON.parse(dataInfo[name])[0].rate)
                        Vue.set(item, 'childList', JSON.parse(dataInfo[name]))
                    }
                }
                // console.log(dataInfo);
                this.realDataList.map((item) => {
                    // 特殊数据处理 
                    // 1.练习总时长 秒转分
                    if (item.name == 'duration') {
                        let avgTime = dataInfo.duration / dataInfo.examStudentNum
                        item.value = `${parseInt(avgTime / 60)}:${avgTime%60}`
                    }
                    // 2.练习平均成绩  练习总成绩 ÷ 练习总人数
                    if (item.title == '平均成绩') {
                        item.value = parseInt(dataInfo.examRate / dataInfo.examStudentNum)
                    }
                    // 3.练习积极的区域 
                    handleMostAndLessData(item, 'mostRateUser');
                    // 4.平均成绩最佳的区域
                    handleMostAndLessData(item, 'lessRateUser')
                })
                // 阅读能力分析右侧文字描述
                this.readingDescData = [];
                for (let i = 1; i <= 5; i++) {
                    let name = `examAbilityInfo${i}`
                    if (dataInfo[name] == '[]') return;
                    this.readingDescData.push(JSON.parse(dataInfo[name])[0])
                }
                if (this.requestType != 6) {
                    this.radarFilterData = JSON.parse(dataInfo.practiceAbility);
                }
            },
            // 模拟学情分析数据(全部)
            async getLeaderActiveReportInfoAll() {
                let params = {
                    stageId: this.$route.params.id,
                    type: 6
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params)
                if (!resData.data || JSON.stringify(resData.data) == '[]') {
                    this.dailyScoreData = [];
                    this.radarData = [];
                    return;
                }
                let dataInfo = resData.data[0];
                // 参与人数
                this.peopleNumber = dataInfo.examStudentNum
                // 折线图线数据 平均
                this.dailyScoreData = dataInfo.dayInfo;
                // 雷达图数据 平均
                this.radarData = JSON.parse(dataInfo.practiceAbility);
            },
            // 获取区域和学校的筛选项
            async getFieldAreaAndSchool() {
                let resData = await this.$Api.Activity.getFieldAreaAndSchool()
                this.areaFilterOptions = this.areaFilterOptions.concat(resData.data);
                // console.log('filter', resData);
                // console.log(this.areaFilterOptions);
            },
            async getFieldGradeAndRole() {
                let resData = await this.$Api.Activity.getFieldGradeAndRole()
                // console.log('GradeAndRole', resData);
                this.gradeFilterOption = this.gradeFilterOption.concat(resData.data.grade);
                // this.roleFilterOption = this.roleFilterOption.concat(resData.data.role);
            },
            // 区域变动学校筛选条件赋值
            areaChange(val) {
                this.schoolFilterOptions = [{
                    id: null,
                    name: '全部'
                }]
                this.schoolValue = null;
                this.checkoutFilter();
                if (!val) {
                    this.getLeaderActiveReportInfo();
                    return;
                }
                for (let i = 0; i < this.areaFilterOptions.length; i++) {
                    if (val == this.areaFilterOptions[i].id) {
                        this.schoolFilterOptions = this.schoolFilterOptions.concat(this.areaFilterOptions[i].children);
                        break;
                    }
                }
                this.getLeaderActiveReportInfo();
            },
            // 学校、年级筛选改变
            filterChange(val) {
                this.checkoutFilter();
                this.getLeaderActiveReportInfo();
            },
            // 筛选情况检查
            checkoutFilter() {
                let area = this.areaValue ? '1' : '0'
                let school = this.schoolValue ? '1' : '0'
                let grade = this.gradeValue ? '1' : '0'
                // console.log(area, school, grade)
                let truthMap = {
                    '000': 6,
                    '001': 9,
                    '010': 4,
                    '011': 3,
                    '100': 5,
                    '101': 8,
                    '110': 4,
                    '111': 3,
                }
                this.requestType = truthMap[`${area}${school}${grade}`];
            },
        },
        mounted() {
            this.getFieldAreaAndSchool();
            this.getFieldGradeAndRole();
            this.getLeaderActiveReportInfo();
            this.getLeaderActiveReportInfoAll();
        }
    }
</script>

<style lang="less" scoped>
    @import "../less/leader_report.less";
</style>