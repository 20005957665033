<template>
    <!-- 大赛报告 组别比例柱状图 -->
    <div class="group-chart" id="group-chart"></div>
</template>

<script>
    export default {
        props: {
            reportType: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                levelData: [], //组别比例数据
                barIcon: [ //柱左侧的不同颜色小头像
                    require('@/assets/images/course/small-boy1.png'),
                    require('@/assets/images/course/small-boy2.png'),
                    require('@/assets/images/course/small-boy3.png'),
                    require('@/assets/images/course/small-boy4.png'),
                    require('@/assets/images/course/small-boy5.png'),
                ],
            }
        },
        methods: {
            // 获取组别比例数据
            async getLevelInfo() {
                let params = {
                    stageId: this.$route.params.id,
                    type: this.reportType,
                }
                let resData = await this.$Api.Activity.getLevelInfo(params)
                // console.log(resData);
                this.levelData = resData.data;
                this.initGroupCharts();
            },
            // 组别比例
            initGroupCharts() {
                var chart = document.getElementById('group-chart');
                var groupChart = this.$echarts.init(chart);
                window.addEventListener('resize', () => {
                    groupChart.resize()
                })
                let colorList = ['#FF655D', '#6ED2D4', '#50ACED', '#FDB94E', '#44E188'];
                let markerColorList = ['rgba(255, 101, 93, 1)',
                    'rgba(110, 210, 212, 1)', 'rgba(80, 172, 237, 1)',
                    'rgba(253, 185, 78, 1)',
                    'rgba(68, 225, 136, 1)'
                ]
                let yAxisData = ['1-2年级', '3-4年级', '5-6年级', '7-9年级', '10-12年级'];
                let data = [100, 50, 65, 60, 55];
                if (JSON.stringify(this.levelData) !== '[]') {
                    data = [];
                    for (let i = 0; i < yAxisData.length; i++) {
                        data.push(this.levelData[yAxisData[i]])
                    }
                }
                // 柱左侧icon
                let iconData = [];
                for (let i = 0; i <= 5; i++) {
                    let item = {
                        value: 1,
                        symbol: `image://${this.barIcon[i]}`
                    }
                    iconData.push(item);
                }

                // console.log(data);
                let option = {
                    color: colorList,
                    grid: {
                        top: "5%",
                        bottom: "10%",
                        left: "17%",
                        right: "28%"
                    },
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            let str = `${params.marker}${params.name}：${data[params.dataIndex]}%`
                            return str
                        }
                    },
                    yAxis: {
                        type: 'category',
                        inverse: true,
                        axisLabel: {
                            show: true,
                            interval: 'auto',
                            color: '#333',
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        data: yAxisData,
                    },
                    xAxis: {
                        show: false,
                    },
                    series: [{
                            // 下层背景
                            type: 'bar',
                            barWidth: '22',
                            barGap: '-100%',
                            z: 0,
                            tooltip: {
                                formatter: function (params) {
                                    let marker =
                                        `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${markerColorList[params.dataIndex]};\"></span>`
                                    let str = `${marker}${params.name}：${data[params.dataIndex]}%`
                                    return str
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        let colorList = ['rgba(255, 101, 93, .2)',
                                            'rgba(110, 210, 212, .2)', 'rgba(80, 172, 237, .2)',
                                            'rgba(253, 185, 78, .2)',
                                            'rgba(68, 225, 136, .2)'
                                        ];
                                        return colorList[params.dataIndex];
                                    },
                                    barBorderRadius: [25, 25, 25, 25]

                                }

                            },
                            data: [100, 100, 100, 100, 100],
                            label: {
                                show: true,
                                color: 'RGBA(251, 147, 108, 1)',
                                offset: [5, 0],
                                position: 'right',
                                formatter: function (params) {
                                    return `平均正确率：${data[params.dataIndex]}%`
                                },
                            },
                        },
                        {
                            //上层数据
                            type: 'bar',
                            barWidth: 22,
                            barGap: '-100%',
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        return colorList[params.dataIndex];
                                    },
                                    barBorderRadius: [25, 25, 25, 25]
                                }

                            },
                            data: data,
                            label: {
                                show: true,
                                position: "insedeLeft",
                                formatter: function (params) {
                                    return ' '
                                },
                            }
                        },
                        {
                            //最左圆片
                            name: '',
                            type: 'pictorialBar',
                            symbolSize: 22,
                            symbolOffset: [-1, 0],
                            z: 3,
                            symbolPosition: 'start',
                            data: iconData,
                        },
                    ],
                }
                groupChart.setOption(option)
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getLevelInfo();
            })

        }
    }
</script>

<style lang="less" scoped>
    .group-chart {
        width: 455px;
        height: 220px;

        @media screen and (min-width:1250px) {
            width: 550px;
            height: 260px;
        }
    }
</style>